
















































































import { ESearch, Pagination, Scrollbar, SvgImage, TailwindTableTabs, TwSelect } from '@/app/components';
import { PlusIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';

import { OrbitSpinner } from 'epic-spinners';
import { FakeRetrievalQueryCard, RetrievalQueryCard } from '../components';
import { useRetrievalQueries } from '../composable';
import { RetrievalStatus } from '../constants';
import { RetrievalQuery } from '../interfaces';

export default defineComponent({
    name: 'RetrievalQueries',
    metaInfo: { title: 'Retrieval' },
    components: {
        PlusIcon,
        OrbitSpinner,
        Pagination,
        RetrievalQueryCard,
        FakeRetrievalQueryCard,
        SvgImage,
        Scrollbar,
        TwSelect,
        TailwindTableTabs,
        ESearch,
    },
    setup(props, { root }) {
        const pageSize = 10;
        const searchableFields = [
            {
                label: 'title',
                key: 'title',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
        ];

        const retrievalQueryStatuses = computed(() => {
            return [
                { label: 'All', key: 'all' },
                ...Object.keys(RetrievalStatus).map((statusLabel: string) => {
                    return {
                        label: statusLabel,
                        key: RetrievalStatus[statusLabel],
                    };
                }),
            ];
        });

        const {
            page,
            query,
            retrievalQueries,
            loading,
            totalResults,
            tab,
            sortBy,
            deleteRetrievalQuery,
            updateCurrentPage,
            sortByChanged,
            setTab,
            sortingFields,
            errors,
        } = useRetrievalQueries(root, 'retrieval', pageSize);

        const deleteQuery = (retrievalQuery: RetrievalQuery) => {
            deleteRetrievalQuery(retrievalQuery)
                .then(() => {
                    (root as any).$toastr.s(`Retrieval Query successfully deleted`, 'Successful deletion!');
                })
                .catch((error) => {
                    (root as any).$toastr.e(error, 'Validation error');
                });
        };

        return {
            tab,
            page,
            pageSize,
            loading,
            retrievalQueries,
            totalResults,
            sortingFields,
            retrievalQueryStatuses,
            query,
            sortBy,
            deleteQuery,
            updateCurrentPage,
            setTab,
            sortByChanged,
            searchableFields,
            errors,
        };
    },
});
