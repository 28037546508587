
























































































































































































import { ConfirmModal, SvgImage } from '@/app/components';
import { onBeforeRouteLeave } from '@/app/composable/router';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ModelsAPI } from '../api';
import {
    ConceptContents,
    CreateConcept,
    EditConcept,
    HighlevelConceptDetails,
    HighlevelConcepts,
    LoadingModal,
    ModelDetails,
} from '../components';
import { useDataModel } from '../composable';
import { FieldFilters, HighLevelConceptFilters, Status, SuggestionStatus } from '../constants';
import { ModelConcept, UpdateModelConcept } from '../types';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'ModelManager',
    metaInfo() {
        return {
            title: (this as any).model ? (this as any).model.name : 'Model Manager',
        };
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        referencedHLConceptId: {
            type: [String, Number],
        },
    },
    components: {
        ConceptContents,
        ConfirmModal,
        CreateConcept,
        EditConcept,
        HighlevelConceptDetails,
        HighlevelConcepts,
        LoadingModal,
        ModelDetails,
        OrbitSpinner,
        SvgImage,
        ChevronLeftIcon,
    },
    setup(props: any, { root }: { root: any }) {
        const collapsedConcepts = ref(false);
        const referencedHLConceptSelected = ref(false);
        const openEditPage = ref(false);
        const openCreatePage = ref(false);
        const loading = ref(false);
        const error = ref(null);
        const deprecationLoader = ref<boolean>(false);
        const cloneAndCopyLoader = ref<string>('');
        const searchFields = ref({
            text: '',
            filterBy: '',
        });
        const clearSearchFields = () => {
            searchFields.value = {
                text: '',
                filterBy: '',
            };
        };
        const scrollToCreatedConcept = ref(null);
        const changesToBeSaved = ref<any>({
            current: {},
            unsaved: {},
        });
        const clearChanges = () => {
            changesToBeSaved.value = {
                current: {},
                unsaved: {},
            };
            clonedChanges.value = R.clone(changesToBeSaved.value);
        };
        const savedChanges = ref<any>({ changes: {}, concepts: [] });
        const showDiscardChangesModal = ref<boolean>(false);
        const discardChangesModalNext = ref<any>(null);
        const afterDiscardingChanges = ref<any>({
            concept: null,
            filter: '',
            highLevel: false,
            callback: null,
        });
        const clearAfterDiscardingChanges = () => {
            afterDiscardingChanges.value = {
                concept: null,
                filter: '',
                highLevel: false,
                callback: null,
            };
        };
        const unblockEdit = ref<boolean>(false);
        const discardChanges = ref<boolean>(false);
        const highLevelConceptOptionSelected = ref(false);
        const notificationParams = ref<any>(root.$route.params ?? null);

        // Selection variables
        const id = computed<number>(() => (R.is(Number, props.id) ? props.id : parseInt(props.id, 10)));
        const selectedHighLevelConcept = ref<any>(null);
        const selectedHighlevelFilter = ref<string>(HighLevelConceptFilters.Active);
        const selectedFieldFilter = ref<string | null>(null);
        const selectedConcept = ref<any>(null);
        const selectedField = ref<any>(null);
        const filteredHLConcepts = ref<any>(null);
        const parent = ref<any>(null);
        const model = ref<any>(null);
        const editingModelDetails = ref(false);
        const {
            isDraft,
            isDeprecated,
            isUnderRevision,
            readOnly,
            defineMessageBasedOnFilter,
            defineSelectedConceptSuggestions,
            defineFilteredHLConcepts,
            defineSelectedHighLevelFilter,
            defineFilterOptions,
            retrieveFields,
            suggestions,
            highlevelConcepts,
            subConcepts,
            defineSuggestions,
            filterConcepts,
        } = useDataModel(model);

        const models = ref<any[]>([]);

        // retrieve all stable (i.e. active) models, but not model's stable self (when current model is in under review status)
        const stableModels = computed(() => {
            const modelsToBeDisplayed = filterConcepts(models.value, HighLevelConceptFilters.Active, false).filter(
                (m: any) => model.value && m.name !== model.value.name,
            );

            return modelsToBeDisplayed.filter((m: any) => m.id.toString() !== id.value.toString());
        });

        const fetchModels = () => {
            loading.value = true;
            ModelsAPI.all().then((res: any) => {
                models.value = res.data;
                loading.value = false;
            });
        };
        fetchModels();

        const highlevelSuggestions = computed(() => {
            if (selectedHighlevelFilter.value === HighLevelConceptFilters.Proposed) {
                return defineFilteredHLConcepts(HighLevelConceptFilters.Proposed);
            }
            return [];
        });

        const selectedConceptSuggestions = computed(() => {
            return selectedHighLevelConcept.value
                ? defineSelectedConceptSuggestions(selectedHighLevelConcept.value.id)
                : [];
        });

        const clonedChanges = ref(R.clone(changesToBeSaved.value));
        const unsavedChanges = computed(
            () =>
                clonedChanges.value &&
                clonedChanges.value.unsaved &&
                Object.keys(clonedChanges.value.unsaved).length > 0,
        );

        /**
         * Sets the actions to be executed if they are any unsaved changes or opens the
         * Concept/ Field Details page where a user can edit the concept/ field
         * @param concept The concept to be edited
         * @param highLevelConcept Whether concept being edited is a high level concept or field
         */
        const editConcept = (concept: any, highLevelConcept: boolean) => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.concept = concept;
                afterDiscardingChanges.value.highLevel = highLevelConcept;

                afterDiscardingChanges.value.callback = () => {
                    editConcept(afterDiscardingChanges.value.concept, afterDiscardingChanges.value.highLevel);
                };
                return;
            }

            highLevelConceptOptionSelected.value = highLevelConcept;
            selectedConcept.value = concept;
            clearChanges();

            if (highLevelConceptOptionSelected.value) {
                selectedHighLevelConcept.value = concept;
                selectedField.value = null;
            } else {
                selectedField.value = concept;
            }
            toggleCreateAndEditPages(false);
        };

        const refreshFields = async (highlevelConceptId: number) => {
            loading.value = true;
            await retrieveFields(highlevelConceptId, referencedHLConceptSelected.value)
                .then(() => {
                    loading.value = false;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.message, 'Error');
                });
        };

        /**
         * Sets the action to be executed if they are any unsaved changes or return to the previous page
         */
        const back = () => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.callback = back;
            } else {
                root.$router.go(-1);
            }
        };

        const setDefaultFieldsFilter = () => {
            if (!referencedHLConceptSelected.value) {
                if (selectedHighlevelFilter.value === HighLevelConceptFilters.Proposed) return FieldFilters.Proposed;
                if (isDraft.value) return FieldFilters.Draft;
                if (isUnderRevision.value) {
                    if (selectedHighLevelConcept.value.status === HighLevelConceptFilters.Draft) {
                        return FieldFilters.New;
                    }
                    return FieldFilters.All;
                }
                if (isDeprecated.value || selectedHighLevelConcept.value.status === HighLevelConceptFilters.Deprecated)
                    return FieldFilters.Deprecated;
            }
            return FieldFilters.All;
        };

        const toggleCreateAndEditPages = (create: boolean) => {
            openEditPage.value = !create;
            openCreatePage.value = create;
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or sets the new
         * selected high level concept and the appropriate layout of the page
         * (i.e. closes the create concept page and opens the edit concept page)
         * @param concept The high level concept which has been selected
         * @param force In order force select the concept
         */
        const selectHighLevelConcept = (concept: any, force = false) => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.concept = concept;
                afterDiscardingChanges.value.callback = () => {
                    selectHighLevelConcept(afterDiscardingChanges.value.concept);
                };
                return;
            }

            if (
                !selectedHighLevelConcept.value ||
                concept.id !== selectedHighLevelConcept.value.id ||
                concept.status !== selectedHighLevelConcept.value.status ||
                force
            ) {
                if (
                    concept.status !== SuggestionStatus.Pending &&
                    selectedHighlevelFilter.value !== HighLevelConceptFilters.Proposed
                ) {
                    referencedHLConceptSelected.value = !!concept.referenceConceptId;
                    refreshFields(referencedHLConceptSelected.value ? concept.referenceConceptId : concept.id);
                } else {
                    subConcepts.value = []; // proposed HL Concepts do not have fields
                }

                editConcept(concept, true);
                // clear default field's filter
                selectedFieldFilter.value = setDefaultFieldsFilter();
            }
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or opens
         * a clean Concept/ Field Creator form
         * @param highLevel Whether the concept which will be created is high level or field
         */
        const addConcept = (highLevelConcept: boolean) => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.highLevel = highLevelConcept;
                afterDiscardingChanges.value.callback = () => {
                    addConcept(afterDiscardingChanges.value.highLevel);
                };

                return;
            }

            // deselect the selected field/ hlconcept if we are going to create a new field/ concept
            selectedField.value = null;
            highLevelConceptOptionSelected.value = false;
            clearChanges();
            toggleCreateAndEditPages(true);
            parent.value = highLevelConcept ? model.value : selectedHighLevelConcept.value;
        };

        const clearSelectedConcept = () => {
            selectedHighLevelConcept.value = null;
            selectedConcept.value = null;
            selectedField.value = null;
            openEditPage.value = false;
            openCreatePage.value = false;
        };

        const filterHLConcepts = (filter: string) => {
            filteredHLConcepts.value = defineFilteredHLConcepts(filter);

            if (filteredHLConcepts.value.length) {
                let referencedConcept = null;
                if (
                    props.referencedHLConceptId ||
                    (notificationParams.value.referenceConceptId &&
                        notificationParams.value.action === Status.Deprecated)
                ) {
                    // if we were navigated to this page, then select the one with the referenceHLConceptId
                    referencedConcept = filteredHLConcepts.value.find(
                        (hlConcept: ModelConcept) =>
                            hlConcept.id ===
                            parseInt(props.referencedHLConceptId || notificationParams.value.referenceConceptId),
                    );
                }
                // if there are any high level concepts, select the first
                selectHighLevelConcept(referencedConcept ? referencedConcept : filteredHLConcepts.value[0], true);
            } else {
                clearSelectedConcept();
            }
        };

        const removeHLConceptsFields = (hlconcepts: any) => {
            hlconcepts.forEach((hlc: any) => {
                if (isUnderRevision.value) {
                    // calculate the no of deprecated fields
                    if (hlc.status === HighLevelConceptFilters.Deprecated) {
                        hlc.noOfFields = hlc.children.filter(
                            (child: any) => child.status === HighLevelConceptFilters.Deprecated,
                        ).length;
                    } else {
                        // calculate the no of 'new' (i.e. draft) and under revision fields
                        hlc.noOfFields = hlc.children.filter(
                            (child: any) =>
                                child.status === Status.UnderRevision || child.status === HighLevelConceptFilters.Draft,
                        ).length;
                    }
                } else if (isDraft.value) {
                    // calculate the no of draft fields
                    hlc.noOfFields = hlc.children.filter(
                        (child: any) => child.status === HighLevelConceptFilters.Draft,
                    ).length;
                } else {
                    hlc.noOfFields = hlc.children.filter((child: any) => child.status === hlc.status).length;
                }

                // remove children from each high level concept
                delete hlc.children;
            });

            return hlconcepts;
        };

        const retrieveSuggestions = async () => {
            await ModelsAPI.getSuggestions(id.value).then((resSuggestions: any) => {
                suggestions.value = defineSuggestions(resSuggestions.data);
            });
        };

        /**
         * Retrieves the model, its high level concepts and proposed concepts/fields
         * and sets the high level concepts filter
         * @param defaultSelection The field to be edited (not a high level concept)
         * @param afterFieldUpdate Whether this function is called after a field has been updated
         */
        const refresh = async (fullRefresh = false) => {
            loading.value = true;

            await ModelsAPI.getModel(id.value)
                .then(async (modelRes: any) => {
                    model.value = modelRes.data;
                    selectedHighlevelFilter.value = defineSelectedHighLevelFilter();

                    // define high level concepts from model (without their children - fields)
                    highlevelConcepts.value = removeHLConceptsFields(R.clone(model.value.children));

                    if (fullRefresh) {
                        filterHLConcepts(selectedHighlevelFilter.value);
                        await retrieveSuggestions();

                        if (notificationParams.value.suggestionId) {
                            let concept = null;
                            let suggestedField = null;
                            let hlConceptWithSuggestedField = null;
                            if (notificationParams.value.parentConceptName) {
                                // for suggested fields

                                const conceptsForSuggestions = suggestions.value.filter(
                                    (s: any) =>
                                        s.status === Status.Stable ||
                                        s.status === Status.UnderRevision ||
                                        (s.status === Status.Deprecated && !s.dateDeprecated),
                                ); // high level concepts might have stable/ under revision statuses based on the status of the model or they have just been deprecated in an In Review Model

                                hlConceptWithSuggestedField = conceptsForSuggestions.find(
                                    (sConcept: any) => sConcept.name === notificationParams.value.parentConceptName,
                                );

                                if (hlConceptWithSuggestedField) {
                                    selectedHighlevelFilter.value = HighLevelConceptFilters.Proposed;
                                    selectedHighLevelConcept.value = hlConceptWithSuggestedField;
                                    suggestedField = suggestions.value.find(
                                        (sField: any) =>
                                            sField.id === notificationParams.value.suggestionId &&
                                            sField.status === SuggestionStatus.Pending,
                                    );
                                    if (suggestedField) {
                                        selectedFieldFilter.value = setDefaultFieldsFilter();
                                        editConcept(suggestedField, false);
                                    }
                                }
                            } else {
                                // for high level concepts
                                concept = suggestions.value.find(
                                    (suggestion: any) => suggestion.id === notificationParams.value.suggestionId,
                                );
                                if (concept) {
                                    selectedHighlevelFilter.value = HighLevelConceptFilters.Proposed;
                                    selectHighLevelConcept(concept);
                                }
                            }
                            if ((!suggestedField || !hlConceptWithSuggestedField) && !concept) {
                                (root as any).$toastr.e(
                                    `The proposed ${
                                        notificationParams.value.parentConceptName ? 'field' : 'concept'
                                    } could not be found, e.g. it has been approved, rejected or could not be found in general.`,
                                    'Error',
                                );
                            }
                            notificationParams.value.suggestionId = null;
                        } else if (
                            notificationParams.value?.referenceConceptId &&
                            notificationParams.value?.action === Status.Deprecated
                        ) {
                            selectedHighlevelFilter.value = HighLevelConceptFilters.Deprecated;
                            filterHLConcepts(HighLevelConceptFilters.Deprecated);
                            notificationParams.value.referenceConceptId = null;
                        }
                    }

                    loading.value = false;
                })
                .catch(() => {
                    // the data model where the suggestion was created, does not exist anymore (i.e. cancelled under revision model)
                    if (notificationParams.value?.suggestionId) {
                        (root as any).$toastr.e(
                            `The data model ${
                                notificationParams.value?.modelName
                                    ? S.sanitizeHtml(notificationParams.value.modelName)
                                    : ''
                            } to which the ${
                                notificationParams.value?.referenceConceptName ? 'concept' : 'field'
                            } was proposed was in Review Mode when the suggestion was made. Please navigate to the data model’s stable version instead to see its details.`,
                            'Error',
                        );
                        root.$router.push({ name: 'model-manager' });
                    }
                });
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or sets
         * the new high level concepts filter
         * @param newFilter New high level concepts filter
         */
        const highlevelConceptFilterChange = (newFilter: string) => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.filter = newFilter;
                afterDiscardingChanges.value.callback = () => {
                    highlevelConceptFilterChange(afterDiscardingChanges.value.filter);
                };

                return;
            }

            selectedHighlevelFilter.value = newFilter;
            filterHLConcepts(newFilter);
        };

        const fieldFilterChange = (newFilter: string) => {
            selectedFieldFilter.value = newFilter;
        };

        const routeReferencedHLConceptNavigation = (highLevelConceptId: number) => {
            if (referencedHLConceptSelected.value) {
                navigateToHLConceptFromAnotherModel({
                    id: selectedHighLevelConcept.value.referenceConceptParentId,
                    referencedHLConceptId: highLevelConceptId,
                });
            } else {
                navigateToReferenceHighLevelConcept(highLevelConceptId);
            }
        };

        const navigateToReferenceHighLevelConcept = (highLevelConceptId: number) => {
            const concept = highlevelConcepts.value
                ? R.find((c: any) => c.id === highLevelConceptId, highlevelConcepts.value)
                : null;

            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.concept = concept;
                afterDiscardingChanges.value.callback = () => {
                    navigateToReferenceHighLevelConcept(afterDiscardingChanges.value.concept.id);
                };
                return;
            }

            if (concept.status === Status.UnderRevision) {
                selectedHighlevelFilter.value = HighLevelConceptFilters.All;
            } else if (
                // in under revision model and the high level concept is new from copied concept from different model
                model.value.status === Status.UnderRevision &&
                concept.status === HighLevelConceptFilters.Draft
            ) {
                selectedHighlevelFilter.value = HighLevelConceptFilters.New;
            } else {
                selectedHighlevelFilter.value =
                    concept.status === Status.Stable ? HighLevelConceptFilters.Active : concept.status;
            }
            selectHighLevelConcept(concept);
        };

        const updateModelStandards = async (standards: any) => {
            const currentModelStandards = model.value.standardsMapping;

            if (standards) {
                for (let i = 0; i < standards.length; i++) {
                    const smapping = standards[i];

                    const alreadyExistsStandard = currentModelStandards.find(
                        (sm: any) =>
                            sm.standard.toLowerCase() === smapping.standard.toLowerCase() &&
                            sm.version.toLowerCase() === smapping.version.toLowerCase(),
                    );
                    if (!alreadyExistsStandard) {
                        currentModelStandards.push({ standard: smapping.standard, version: smapping.version });
                    }
                }
            }
            model.value.standardsMapping = currentModelStandards;
            await ModelsAPI.updateConcept(model.value.id, { ...model.value } as UpdateModelConcept).then((res: any) => {
                model.value = { ...model.value, ...res.data };
            });
        };

        const createdHLConcept = async (concept: any) => {
            clearSearchFields();
            await updateModelStandards(concept.standardsMapping);
            await refresh();
            scrollToCreatedConcept.value = concept;
            selectHighLevelConcept(concept);
        };

        const createdField = async (field: any, suggestedField = false) => {
            await refresh(); // to retrieve the updated version of the model
            await updateModelStandards(field.standardsMapping);
            refreshFields(field.parentId);
            const idx = R.findIndex(R.propEq('id', field.parentId), highlevelConcepts.value);

            if (~idx) {
                if (suggestedField) {
                    selectedHighLevelConcept.value = highlevelConcepts.value[idx];
                    selectedHighlevelFilter.value = defineSelectedHighLevelFilter();
                }
            }

            selectedField.value = field;
            selectedConcept.value = field;
            highLevelConceptOptionSelected.value = false;
            clearSearchFields();
            selectedFieldFilter.value = setDefaultFieldsFilter();
            scrollToCreatedConcept.value = field;
            toggleCreateAndEditPages(false);
        };

        const deprecatedConcept = async (modelId?: number) => {
            if (highLevelConceptOptionSelected.value) {
                if (model.value.status === Status.Stable) {
                    root.$router.push({ name: 'model-manager:edit', params: { id: modelId } });
                } else {
                    await refresh(true);
                }
            } else {
                await refresh(); // to retrieve the updated version of the model
                const idx = R.findIndex(R.propEq('id', selectedField.value.parentId), highlevelConcepts.value);

                selectedField.value = null;
                clearSearchFields();
                selectedHighlevelFilter.value = defineSelectedHighLevelFilter();
                selectHighLevelConcept(highlevelConcepts.value[idx], true);
            }
        };

        const updateSavedChanges = (concept: any) => {
            Object.keys(changesToBeSaved.value.unsaved).forEach((field: any) => {
                if (concept.uid in savedChanges.value.changes) {
                    if (!savedChanges.value.changes[concept.uid].includes(field)) {
                        savedChanges.value.changes[concept.uid].push(field);
                    }
                } else {
                    savedChanges.value.changes[concept.uid] = [field];
                }
            });

            // update saved concepts
            if (!savedChanges.value.concepts.includes(concept.uid)) savedChanges.value.concepts.push(concept.uid);
        };

        const updateConcept = async (concept: any) => {
            await updateModelStandards(concept.standardsMapping);
            await refresh();
            // add updated fields to saved changes
            updateSavedChanges(concept);

            if (highLevelConceptOptionSelected.value) {
                selectedHighLevelConcept.value = concept;

                const idx = R.findIndex(R.propEq('id', concept.id), suggestions.value);
                if (~idx) {
                    const updatedHLConceptInSuggestions = {
                        ...concept,
                        noOfSuggestedFields: suggestions.value[idx].noOfSuggestedFields,
                    };
                    suggestions.value[idx] = updatedHLConceptInSuggestions;
                }
            } else {
                // when a field has a change and it's saved, the high level concept of that field is considered as saved too
                if (!savedChanges.value.concepts.includes(selectedHighLevelConcept.value.uid))
                    savedChanges.value.concepts.push(selectedHighLevelConcept.value.uid);
                refreshFields(concept.parentId);
                selectedField.value = concept;
                selectedFieldFilter.value = setDefaultFieldsFilter();
            }

            clearChanges();
            selectedConcept.value = concept;
        };

        const createProposedConcept = async (proposedConcept: any) => {
            const isHighLevelConcept = !selectedConcept.value.parentId;

            await ModelsAPI.approveSuggestion(proposedConcept.id, proposedConcept)
                .then((res: any) => {
                    const approvedConcept = res.data;

                    (root as any).$toastr.s(
                        `Proposed ${isHighLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                            approvedConcept.name,
                        )}' is approved and created successfully`,
                        'Success',
                    );
                    if (isHighLevelConcept) {
                        createdHLConcept(approvedConcept);
                    } else {
                        createdField(approvedConcept, true);
                    }
                })
                .catch((e) => {
                    (root as any).$toastr.e(
                        `Proposed ${isHighLevelConcept ? 'Concept' : 'Field'} '${S.sanitizeHtml(
                            selectedConcept.value.name,
                        )}' failed to be approved due to an error: ${e.response.data.message}`,
                        'Error',
                    );
                    error.value = e;
                });
        };

        const rejectProposedConcept = async () => {
            await retrieveSuggestions(); // update suggestions
            clearSearchFields();
            if (highLevelConceptOptionSelected.value) {
                filterHLConcepts(selectedHighlevelFilter.value);
            } else if (
                selectedHighlevelFilter.value === HighLevelConceptFilters.Active ||
                selectedHighlevelFilter.value === HighLevelConceptFilters.All
            ) {
                // when a suggested field is rejected when in 'Active' tab of HLConcepts and in 'Proposed' tab of fields
                selectedField.value = null; // clear the selected field
                selectedConcept.value = selectedHighLevelConcept.value; // set selected concept as the high level concept of the rejected field
                editConcept(selectedConcept.value, true); // open the selected high level concept in the edit page
            } else if (selectedHighlevelFilter.value === HighLevelConceptFilters.Proposed) {
                const idx = R.findIndex(R.propEq('id', selectedField.value.parentId), suggestions.value);
                if (~idx) {
                    suggestions.value[idx].noOfSuggestedFields -= 1;
                    selectedConcept.value = suggestions.value[idx];
                    highLevelConceptOptionSelected.value = true;
                } else {
                    filterHLConcepts(selectedHighlevelFilter.value);
                }
                selectedField.value = null;
            }
        };

        const filterOptions = computed(() => defineFilterOptions());

        const messageBasedOnFilter = computed(() => {
            return selectedHighlevelFilter.value
                ? defineMessageBasedOnFilter(selectedHighlevelFilter.value)
                : 'Add a High Level Concept to get started';
        });

        const deprecationLoading = (activate: boolean) => {
            deprecationLoader.value = activate;
        };

        const checkConceptChanges = (field: string, value: any, isModel = false) => {
            if (field === 'standardsMapping') value = value.length ? value : null; // in order to replace empty array [] with null as standardsMapping default value is null
            changesToBeSaved.value.current[field] = isModel ? model.value[field] : selectedConcept.value[field];

            if (JSON.stringify(changesToBeSaved.value.current[field]) !== JSON.stringify(value)) {
                changesToBeSaved.value.unsaved[field] = value;
            } else {
                // remove key-value from changes if current and unsaved changes are the same
                delete changesToBeSaved.value.unsaved[field];
            }

            clonedChanges.value = R.clone(changesToBeSaved.value);
        };

        const change = (field: string, value: any, isModel = false) => {
            // if isModel true => changes are performed on the model concept
            if (isModel || selectedConcept.value.status !== SuggestionStatus.Pending) {
                checkConceptChanges(field, value, isModel);
            }
        };

        const confirmDiscardChanges = () => {
            clearChanges();
            showDiscardChangesModal.value = false;

            if (discardChangesModalNext.value) {
                discardChangesModalNext.value();
                discardChangesModalNext.value = null;
            } else {
                afterDiscardingChanges.value.callback();
                clearAfterDiscardingChanges();
            }
        };

        const cancelDiscardChanges = () => {
            showDiscardChangesModal.value = false;
            clearAfterDiscardingChanges();
            discardChangesModalNext.value = null;
        };

        onBeforeRouteLeave((to: any, from: any, next: any) => {
            if (unsavedChanges.value) {
                showDiscardChangesModal.value = true;
                discardChangesModalNext.value = next;
            } else {
                next();
            }
        });

        const cloneAndCopyLoading = (action: string) => {
            cloneAndCopyLoader.value = action;
        };

        const updateSearchFields = (updatedSearchFields: { text: string; filterBy: string }) => {
            searchFields.value = updatedSearchFields;
        };

        const unsavedHLConcept = computed(() => {
            let unsaved = null;
            if (savedChanges.value.concepts.includes(selectedHighLevelConcept.value.uid)) unsaved = false;
            if (
                selectedHighLevelConcept.value &&
                clonedChanges.value.unsaved &&
                Object.keys(clonedChanges.value.unsaved).length > 0
            )
                unsaved = true;
            return unsaved;
        });

        const updateModel = (updatedModel: any) => {
            model.value = { ...model.value, ...updatedModel };

            // add updated fields to saved changes
            updateSavedChanges(model.value);

            editingModelDetails.value = false;
            clearChanges();
            refresh();
        };

        const closeModelDetailsModal = () => {
            unblockEdit.value = false;
            discardChanges.value = false;
            editingModelDetails.value = false;
            clearChanges();
        };

        const readOnlyFields = computed(
            () =>
                selectedConcept.value?.readOnly ||
                (referencedHLConceptSelected.value && !highLevelConceptOptionSelected.value) ||
                (selectedHighLevelConcept.value && selectedHighLevelConcept.value?.status === Status.Deprecated),
        );

        const navigateToHLConceptFromAnotherModel = (event: { id: number; referencedHLConceptId: number }) => {
            root.$router.push({
                name: 'model-manager:edit',
                params: {
                    id: event.id,
                    referencedHLConceptId: event.referencedHLConceptId,
                },
            });
        };

        const changeId = () => {
            refresh(true);
            clearChanges();
            deprecationLoader.value = false;
        };

        watch(
            () => id.value,
            (newId, oldId) => {
                if (oldId !== newId) changeId();
            },
            { immediate: true },
        );

        return {
            addConcept,
            afterDiscardingChanges,
            back,
            change,
            navigateToReferenceHighLevelConcept,
            changesToBeSaved,
            collapsedConcepts,
            createdField,
            createdHLConcept,
            highLevelConceptOptionSelected,
            createProposedConcept,
            deprecatedConcept,
            deprecationLoader,
            deprecationLoading,
            discardChanges,
            discardChangesModalNext,
            editConcept,
            error,
            filteredHLConcepts,
            filterOptions,
            highlevelConceptFilterChange,
            highlevelConcepts,
            selectHighLevelConcept,
            highlevelSuggestions,
            isDraft,
            isUnderRevision,
            loading,
            messageBasedOnFilter,
            model,
            openCreatePage,
            openEditPage,
            parent,
            readOnly,
            refresh,
            rejectProposedConcept,
            selectedFieldFilter,
            selectedHighLevelConcept,
            selectedHighlevelFilter,
            selectedConcept,
            selectedConceptSuggestions,
            showDiscardChangesModal,
            Status,
            subConcepts,
            suggestions,
            unblockEdit,
            updateConcept,
            stableModels,
            cloneAndCopyLoading,
            cloneAndCopyLoader,
            fieldFilterChange,
            SuggestionStatus,
            selectedField,
            searchFields,
            updateSearchFields,
            scrollToCreatedConcept,
            clonedChanges,
            savedChanges,
            unsavedHLConcept,
            unsavedChanges,
            updateModel,
            confirmDiscardChanges,
            cancelDiscardChanges,
            closeModelDetailsModal,
            editingModelDetails,
            referencedHLConceptSelected,
            routeReferencedHLConceptNavigation,
            navigateToHLConceptFromAnotherModel,
            notificationParams,
            readOnlyFields,
        };
    },
});
